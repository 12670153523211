import { preloadFonts } from './utils';
import Lenis from '@studio-freight/lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

// Utility to detect mobile devices
const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// Only enable splitting on desktop for better mobile performance
if (!isMobile()) {
    Splitting();
}

// Title animation targets
const fx16Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect16]')];
const fx17Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect17]')];
const fx18Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect18]')];
const fx19Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect19]')];
const fx20Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect20]')];
const fx21Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect21]')];
const fx22Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect22]')];
const fx23Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect23]')];
const fx24Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect24]')];
const fx25Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect25]')];
const fx26Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect26]')];
const fx27Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect27]')];
const fx28Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect28]')];
const fx29Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect29]')];

// Lenis smooth scrolling
let lenis;

// Initialize Lenis smooth scrolling with mobile check
const initSmoothScrolling = () => {
    lenis = new Lenis({
        lerp: isMobile() ? 0.1 : 0.2, // Reduce lerp for mobile
        smooth: true,
        smoothTouch: false, // Disable smooth touch scrolling for mobile to avoid performance issues
    });

    lenis.on('scroll', () => ScrollTrigger.update());

    const scrollFn = (time) => {
        lenis.raf(time);
        requestAnimationFrame(scrollFn);
    };

    requestAnimationFrame(scrollFn);
};

// GSAP ScrollTrigger animations
const scroll = () => {
    fx16Titles.forEach(title => {
        gsap.fromTo(title, {
            transformOrigin: '0% 50%',
            rotate: 3
        }, {
            ease: 'none',
            rotate: 0,
            scrollTrigger: {
                trigger: title,
                start: 'top bottom',
                end: 'top top',
                scrub: true,
            }
        });

        gsap.fromTo(title.querySelectorAll('.word'), {
            'will-change': 'opacity',
            opacity: 0.1
        }, {
            ease: 'none',
            opacity: 1,
            stagger: 0.05,
            scrollTrigger: {
                trigger: title,
                start: 'top bottom-=20%',
                end: 'center top+=20%',
                scrub: true,
                markers: false // Disable markers on mobile
            }
        });
    });

    // Example for fx17Titles with ScrollTrigger batch
    ScrollTrigger.batch(fx17Titles, {
        onEnter: batch => gsap.to(batch, {
            opacity: 1,
            rotateX: 0,
            stagger: 0.05,
            scrub: true,
            scrollTrigger: {
                start: 'top bottom',
                end: 'bottom top',
                scrub: true
            }
        }),
        onLeave: batch => gsap.to(batch, { opacity: 0 }),
        batchMax: isMobile() ? 3 : 6 // Reduce batch size for mobile devices
    });

    fx18Titles.forEach(title => {
        const chars = title.querySelectorAll('.char');
        chars.forEach(char => gsap.set(char.parentNode, { perspective: 1000 }));

        gsap.fromTo(chars, {
            'will-change': 'opacity, transform',
            opacity: 0.2,
            z: -800
        }, {
            ease: 'back.out(1.2)',
            opacity: 1,
            z: 0,
            stagger: 0.04,
            scrollTrigger: {
                trigger: title,
                start: 'top bottom',
                end: 'bottom top',
                scrub: true,
            }
        });
    });

    fx19Titles.forEach(title => {
        const chars = title.querySelectorAll('.char');
        chars.forEach(char => gsap.set(char.parentNode, { perspective: 1000 }));

        gsap.fromTo(chars, {
            'will-change': 'opacity, transform',
            transformOrigin: '50% 0%',
            opacity: 0,
            rotationX: -90,
            z: -200
        }, {
            ease: 'power1',
            opacity: 1,
            stagger: 0.05,
            rotationX: 0,
            z: 0,
            scrollTrigger: {
                trigger: title,
                start: 'center bottom',
                end: 'bottom top+=20%',
                scrub: true,
            }
        });
    });

    // Apply similar optimizations for fx20Titles, fx21Titles, etc.

    fx28Titles.forEach(title => {
        const words = [...title.querySelectorAll('.word')];
        for (const word of words) {
            const chars = word.querySelectorAll('.char');
            const charsTotal = chars.length;

            gsap.fromTo(chars, {
                'will-change': 'transform, filter',
                transformOrigin: '50% 100%',
                scale: position => {
                    const factor = position < Math.ceil(charsTotal / 2) ? position : Math.ceil(charsTotal / 2) - Math.abs(Math.floor(charsTotal / 2) - position) - 1;
                    return gsap.utils.mapRange(0, Math.ceil(charsTotal / 2), 0.5, 2.1, factor);
                },
                y: position => {
                    const factor = position < Math.ceil(charsTotal / 2) ? position : Math.ceil(charsTotal / 2) - Math.abs(Math.floor(charsTotal / 2) - position) - 1;
                    return gsap.utils.mapRange(0, Math.ceil(charsTotal / 2), 0, 60, factor);
                },
                rotation: position => {
                    const factor = position < Math.ceil(charsTotal / 2) ? position : Math.ceil(charsTotal / 2) - Math.abs(Math.floor(charsTotal / 2) - position) - 1;
                    return position < charsTotal / 2 ? gsap.utils.mapRange(0, Math.ceil(charsTotal / 2), -4, 0, factor) : gsap.utils.mapRange(0, Math.ceil(charsTotal / 2), 0, 4, factor);
                },
                filter: 'blur(12px) opacity(0)',
            }, {
                ease: 'power2.inOut',
                y: 0,
                rotation: 0,
                scale: 1,
                filter: 'blur(0px) opacity(1)',
                scrollTrigger: {
                    trigger: word,
                    start: 'top bottom+=40%',
                    end: 'top top+=15%',
                    scrub: true,
                },
                stagger: {
                    amount: 0.15,
                    from: 'center'
                }
            });
        }
    });
};

// Preload images and fonts
preloadFonts('cvn8slu').then(() => {
    // Remove loader (loading class)
    document.body.classList.remove('loading');

    // Initialize Lenis smooth scrolling
    initSmoothScrolling();

    // GSAP Scroll Triggers
    scroll();
});
